<template>
  <div class="container">
    <VueLoading v-model:active="state.isLoading" color="#196bb0"></VueLoading>
    <div class="select-row row my-3 pb-3">
      <div class="col-md-4">
        <select class="form-select" v-model="selectedProjectId" @change="getSurveyList">
          <option selected disabled hidden value="">--請選擇計畫--</option>
          <option
            v-for="(project, index) in projects"
            :value="project.id"
            :key="'project_' + index"
          >
            {{ project.name }}
          </option>
        </select>
      </div>
      <div class="col-md-4" v-show="selectedProjectId">
        <select class="form-select" v-model="selectedSurvey" @change="getSurveyList">
          <option selected disabled hidden value="">--請選擇量表--</option>
          <option value="basicinfo">個案基本資料</option>
          <option value="eat10">吞嚥能力評估工具</option>
          <option value="fois">功能性由口進食量表</option>
          <option value="mnasf">迷你營養評估量表</option>
          <option value="sf36">身心健康狀況量表</option>
          <option value="sarcf">SARC-F 量表</option>
          <option value="ad8">極早期失智症篩檢量表</option>
          <option value="cdr">臨床失智評估量表</option>
          <option value="mmse">簡易心智量表</option>
        </select>
      </div>
      <div class="col-md-4" v-if="selectedProjectId && selectedSurvey">
        <button class="btn btn-primary" @click="export2Excel">
          匯出
        </button>
      </div>
    </div>
    <div class="code-row row">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">編號</th>
            <th scope="col">欄位名稱</th>
            <th scope="col">類型</th>
            <th scope="col">資料描述</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(field, fieldIndex) in codeBody" :key="'field_' + fieldIndex">
            <th>
              {{ fieldIndex + 1 }}
            </th>
            <td v-for="(content, contentIndex) in field" :key="'content_' + contentIndex">
              {{ content }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// 載入 Vue 3 composition api
// eslint-disable-next-line object-curly-newline
import { ref, toRefs, onMounted, watch } from 'vue';
// 載入 firestore
// eslint-disable-next-line object-curly-newline
import { getDocs, query, collection, where } from 'firebase/firestore';
// 載入 lodash
import _ from 'lodash';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';
import { db } from '@/assets/firebase';
import useUserProjects from '@/composables/useUserProjects';
import { exportJsonToExcel } from '@/util/Export2Excel';

export default {
  components: {
    VueLoading,
  },
  props: {
    userUid: String,
    userEmail: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    // ================================
    const { userUid } = toRefs(props);
    const { projects, state, getProjects } = useUserProjects(userUid.value);
    const selectedProjectId = ref('');
    const selectedSurvey = ref('');
    const selectedSurveyList = ref([]);
    const selectedCaseList = ref([]);
    const codeBody = ref([]);

    // 從個案列表搜尋特定的個案檔案
    function findCaseData(caseId) {
      const data = selectedCaseList.value.find((caseData) => caseData.id === caseId);
      return data;
    }
    // 從計畫列表尋找特定的計畫檔案
    function findProjectData(projectId) {
      const data = projects.value.find((project) => project.id === projectId);
      return data;
    }
    // 取得所有量表檔案
    async function getSurveyList() {
      if (selectedProjectId.value && selectedSurvey.value) {
        state.isLoading = true;
        selectedSurveyList.value = [];
        selectedCaseList.value = [];
        try {
          // 讀取所有使用者選擇的計畫之所有個案檔案
          const caseQuerySnapshot = await getDocs(
            collection(db, 'projects', selectedProjectId.value, 'cases'),
          );
          caseQuerySnapshot.forEach((caseData) => {
            const casesData = _.cloneDeep(caseData.data());
            casesData.id = caseData.id;
            selectedCaseList.value.push(casesData);
          });
          // 讀取使用者選擇的計畫與量表類別之所有量表
          const q = query(
            collection(db, selectedSurvey.value),
            where('projectId', '==', selectedProjectId.value),
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((survey) => {
            const surveyData = _.cloneDeep(survey.data());
            const caseData = findCaseData(surveyData.caseId);
            surveyData.sn = caseData.sn;
            surveyData.name = caseData.name;
            selectedSurveyList.value.push(surveyData);
          });
          state.isLoading = false;
        } catch (e) {
          state.isLoading = false;
          $swal.fire({
            text: String(e),
            icon: 'error',
          });
        }
      }
    }
    // 將 timestamp 轉換為 日期字串
    function getDateString(timestamp) {
      const date = new Date(timestamp);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      if (hour < 10) {
        hour = `0${hour}`;
      }
      if (minute < 10) {
        minute = `0${minute}`;
      }
      // 檢測是否為 NaN
      if (Number.isNaN(year)) {
        year = '0000';
      }
      if (Number.isNaN(month)) {
        month = '00';
      }
      if (Number.isNaN(day)) {
        day = '00';
      }
      if (Number.isNaN(hour)) {
        hour = '00';
      }
      if (Number.isNaN(minute)) {
        minute = '00';
      }
      return `${year}年${month}月${day}日 ${hour}:${minute}`;
    }
    // 格式化輸出之 Json
    function formatJson(filterVal, jsonData) {
      jsonData.map((v) => filterVal.map((j) => v[j]));
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    }
    // 整理輸出的表頭內容
    function sortTableHead() {
      const tHead = [
        { label: '編號', prop: 'sn' },
        { label: '名稱', prop: 'name' },
      ];
      const tFoot = [
        { label: '建立日期', prop: 'createdAt' },
        { label: '最後更新日期', prop: 'updatedAt' },
      ];
      let tBody = [];
      // 依照量表類別調整輸出 body
      switch (selectedSurvey.value) {
        case 'basicinfo':
          tBody = [
            { label: '身高(cm)', prop: 'height' },
            { label: '體重(kg)', prop: 'weight' },
            { label: 'BMI', prop: 'bmi' },
            { label: '腰圍(cm)', prop: 'waistline' },
            { label: '活動量', prop: 'activity' },
            { label: '每日熱量需求(Kcal)', prop: 'calories' },
            { label: '體脂肪(%)', prop: 'fat' },
            { label: '肌肉量(kg)', prop: 'muscle' },
            { label: '三頭肌皮下脂肪厚度(mm)', prop: 'tsf' },
            { label: '收縮壓(mmHg)', prop: 'sbp' },
            { label: '舒張壓(mmHg)', prop: 'dbp' },
            { label: '備註', prop: 'note' },
          ];
          break;
        case 'eat10':
          tBody = [
            { label: 'Q1', prop: 'q1' },
            { label: 'Q2', prop: 'q2' },
            { label: 'Q3', prop: 'q3' },
            { label: 'Q4', prop: 'q4' },
            { label: 'Q5', prop: 'q5' },
            { label: 'Q6', prop: 'q6' },
            { label: 'Q7', prop: 'q7' },
            { label: 'Q8', prop: 'q8' },
            { label: 'Q9', prop: 'q9' },
            { label: 'Q10', prop: 'q10' },
          ];
          break;
        case 'fois':
          tBody = [{ label: 'Level', prop: 'level' }];
          break;
        case 'mnasf':
          tBody = [
            { label: 'Q1', prop: 'q1' },
            { label: 'Q2', prop: 'q2' },
            { label: 'Q3', prop: 'q3' },
            { label: 'Q4', prop: 'q4' },
            { label: 'Q5', prop: 'q5' },
            { label: 'Q6_1', prop: 'q6_1' },
            { label: 'Q6_2', prop: 'q6_2' },
            { label: '總分', prop: 'total' },
            { label: '下降體重(kg)', prop: 'loseWeight' },
            { label: '身高(cm)', prop: 'height' },
            { label: '體重(kg)', prop: 'weight' },
            { label: 'BMI', prop: 'bmi' },
            { label: '小腿圍(cm)', prop: 'cc' },
          ];
          break;
        case 'sf36':
          tBody = [
            { label: 'Q1', prop: 'q1' },
            { label: 'Q2', prop: 'q2' },
            { label: 'Q3_A', prop: 'q3_1' },
            { label: 'Q3_B', prop: 'q3_2' },
            { label: 'Q3_C', prop: 'q3_3' },
            { label: 'Q3_D', prop: 'q3_4' },
            { label: 'Q3_E', prop: 'q3_5' },
            { label: 'Q3_F', prop: 'q3_6' },
            { label: 'Q3_G', prop: 'q3_7' },
            { label: 'Q3_H', prop: 'q3_8' },
            { label: 'Q3_I', prop: 'q3_9' },
            { label: 'Q3_J', prop: 'q3_10' },
            { label: 'Q4_A', prop: 'q4_1' },
            { label: 'Q4_B', prop: 'q4_2' },
            { label: 'Q4_C', prop: 'q4_3' },
            { label: 'Q4_D', prop: 'q4_4' },
            { label: 'Q5_A', prop: 'q5_1' },
            { label: 'Q5_B', prop: 'q5_2' },
            { label: 'Q5_C', prop: 'q5_3' },
            { label: 'Q6', prop: 'q6' },
            { label: 'Q7', prop: 'q7' },
            { label: 'Q8', prop: 'q8' },
            { label: 'Q9_A', prop: 'q9_1' },
            { label: 'Q9_B', prop: 'q9_2' },
            { label: 'Q9_C', prop: 'q9_3' },
            { label: 'Q9_D', prop: 'q9_4' },
            { label: 'Q9_E', prop: 'q9_5' },
            { label: 'Q9_F', prop: 'q9_6' },
            { label: 'Q9_G', prop: 'q9_7' },
            { label: 'Q9_H', prop: 'q9_8' },
            { label: 'Q9_I', prop: 'q9_9' },
            { label: 'Q10', prop: 'q10' },
            { label: 'Q11_A', prop: 'q11_1' },
            { label: 'Q11_B', prop: 'q11_2' },
            { label: 'Q11_C', prop: 'q11_3' },
            { label: 'Q11_D', prop: 'q11_4' },
          ];
          break;
        case 'sarcf':
          tBody = [
            { label: 'Q1', prop: 'q1' },
            { label: 'Q2', prop: 'q2' },
            { label: 'Q3', prop: 'q3' },
            { label: 'Q4', prop: 'q4' },
            { label: 'Q5', prop: 'q5' },
            { label: '總分', prop: 'total' },
          ];
          break;
        case 'ad8':
          tBody = [
            { label: 'Q1', prop: 'q1' },
            { label: 'Q2', prop: 'q2' },
            { label: 'Q3', prop: 'q3' },
            { label: 'Q4', prop: 'q4' },
            { label: 'Q5', prop: 'q5' },
            { label: 'Q6', prop: 'q6' },
            { label: 'Q7', prop: 'q7' },
            { label: 'Q8', prop: 'q8' },
            { label: '總分', prop: 'total' },
          ];
          break;
        case 'cdr':
          tBody = [
            { label: '記憶力', prop: 'memory' },
            { label: '定向感', prop: 'orientation' },
            { label: '解決問題能力', prop: 'solve' },
            { label: '社區活動能力', prop: 'activity' },
            { label: '家居嗜好', prop: 'hobby' },
            { label: '自我照料', prop: 'care' },
          ];
          break;
        case 'mmse':
          tBody = [
            { label: '教育程度', prop: 'education' },
            { label: '職業', prop: 'career' },
            { label: '總分', prop: 'total' },
          ];
          break;
        default:
          return 'error';
      }
      return [...tHead, ...tBody, ...tFoot];
    }
    // 整理輸出的表單內容
    function sortTableBody() {
      const output = _.cloneDeep(selectedSurveyList.value);
      // 轉換日期為可閱讀字串
      output.forEach((data, index) => {
        output[index].createdAt = getDateString(data.createdAt);
        output[index].updatedAt = getDateString(data.updatedAt);
      });
      // 針對不同量表類型整理輸出檔案
      switch (selectedSurvey.value) {
        case 'basicinfo':
          output.forEach((survey, surveyIndex) => {
            if (survey.activity === '') {
              output[surveyIndex].activity = '0';
            }
          });
          break;
        case 'sf36':
          output.forEach((survey, surveyIndex) => {
            survey.ans.forEach((ans, ansIndex) => {
              // 如果該題答案為「物件」，表示有小題
              if (typeof ans === 'object') {
                ans.path.forEach((subAns, subIndex) => {
                  const propName = `q${ansIndex + 1}_${subIndex + 1}`;
                  output[surveyIndex][propName] = subAns;
                });
              } else {
                // 不是「物件」則單純放入
                const propName = `q${ansIndex + 1}`;
                output[surveyIndex][propName] = ans;
              }
            });
          });
          break;
        default:
          break;
      }
      return output;
    }
    // 輸出 Excel
    function export2Excel() {
      require.ensure([], () => {
        const tHeader = []; // 對應表格輸出的中文title
        const filterVal = []; // 對應表格輸出的數據
        const tableHead = sortTableHead();
        const leadsData = sortTableBody();
        tableHead.forEach((val) => {
          tHeader.push(val.label);
          filterVal.push(val.prop);
        });
        const list = leadsData; // 表格data
        const data = formatJson(filterVal, list);
        const now = new Date();
        let fileName = `${findProjectData(selectedProjectId.value).name}_`;
        fileName += `${selectedSurvey.value}_`;
        fileName += now.getFullYear();
        fileName += now.getMonth() + 1;
        fileName += now.getDate();
        fileName += now.getHours();
        fileName += now.getMinutes();
        exportJsonToExcel(tHeader, data, fileName); // 對應下載文件的名字
      });
    }
    // 整理 Codebook 內容
    function sortCodeBook() {
      // 依照量表類別調整輸出 CodeBody
      switch (selectedSurvey.value) {
        case 'basicinfo':
          codeBody.value = [
            ['身高(cm)', 'Num', '0: 未回答'],
            ['體重(kg)', 'Num', '0: 未回答'],
            ['BMI', 'Num', '0: 未回答'],
            ['腰圍(cm)', 'Num', '0: 未回答'],
            ['活動量', 'Num', '0: 未回答\n1: 臥床\n2: 輕度\n3: 中度\n4: 高度'],
            ['每日熱量需求(Kcal)', 'Char', '0: 未回答'],
            ['體脂肪(%)', 'Num', '0: 未回答'],
            ['肌肉量(kg)', 'Num', '0: 未回答'],
            ['三頭肌皮下脂肪厚度(mm)', 'Num', '0: 未回答'],
            ['收縮壓(mmHg)', 'Num', '0: 未回答'],
            ['舒張壓(mmHg)', 'Num', '0: 未回答'],
            ['備註', 'Char', ''],
          ];
          break;
        case 'eat10':
          codeBody.value = [
            ['所有題目', 'Num', '1: 沒有問題 ~ 5: 嚴重問題'],
          ];
          break;
        case 'fois':
          codeBody.value = [
            ['所有題目', 'Num', '1: 等級一 ~ 7: 等級七'],
          ];
          break;
        case 'mnasf':
          codeBody.value = [
            ['Q1', 'Num', ''],
            ['Q2', 'Num', ''],
            ['Q3', 'Num', ''],
            ['Q4', 'Num', ''],
            ['Q5', 'Num', ''],
            ['Q6_1', 'Num', 'Q6 以 BMI 為依據的分數'],
            ['Q6_2', 'Num', 'Q6 以小腿圍為依據的分數'],
            ['總分', 'Num', ''],
            ['下降體重(kg)', 'Num', '8888: 不知道'],
            ['身高(cm)', 'Num', '0: 未回答'],
            ['體重(kg)', 'Num', '0: 未回答'],
            ['BMI', 'Num', '0: 未回答'],
            ['小腿圍(cm)', 'Num', '0: 未回答'],
          ];
          break;
        case 'sf36':
          codeBody.value = [
            ['Q1', 'Num', '1: 極好\n2: 很好\n3: 好\n4: 普通\n5: 不好'],
            ['Q2', 'Num', '1: 好很多\n2: 好一些\n3: 差不多\n4: 差一點\n5: 差很多'],
            ['Q3', 'Num', '1: 會，受很多限制\n2: 會，受到一些限制\n3: 不會，完全不受限制'],
            ['Q4', 'Num', '1: 一直都是\n2: 大部份時間\n3: 有時\n4: 很少\n5: 從不'],
            ['Q5', 'Num', '1: 一直都是\n2: 大部份時間\n3: 有時\n4: 很少\n5: 從不'],
            ['Q6', 'Num', '1: 完全沒有妨礙\n2: 有一點妨礙\n3: 中度妨礙\n4: 相當多妨礙\n5: 妨礙到極點'],
            ['Q7', 'Num', '1: 完全不\n2: 非常輕微\n3: 輕微\n4: 中度\n5: 嚴重\n6: 非常嚴重'],
            ['Q8', 'Num', '1: 完全沒有妨礙\n2: 有一點妨礙\n3: 中度妨礙\n4: 相當多妨礙\n5: 妨礙到極點'],
            ['Q9', 'Num', '1: 一直都是\n2: 大部份時間\n3: 有時\n4: 很少\n5: 從不'],
            ['Q10', 'Num', '1: 一直都會\n2: 大部份時間會\n3: 有時會\n4: 很少會\n5: 從不會'],
            ['Q11', 'Num', '1: 一直都是\n2: 大部份時間\n3: 有時\n4: 很少\n5: 從不'],
          ];
          break;
        case 'sarcf':
          codeBody.value = [
            ['所有題目', 'Num', ''],
          ];
          break;
        case 'ad8':
          codeBody.value = [
            ['所有題目', 'Num', '8888: 不知道，且分數為 0'],
          ];
          break;
        case 'cdr':
          codeBody.value = [
            ['所有題目', 'Num', ''],
          ];
          break;
        case 'mmse':
          codeBody.value = [
            ['教育程度', 'Num', '1: 未受教育\n2: 國小\n3: 國中(含)以上'],
            ['職業', 'Char', ''],
            ['總分', 'Num', ''],
          ];
          break;
        default:
          break;
      }
    }

    onMounted(() => {
      // 讀取所有使用者能檢視之計畫案
      getProjects();
    });
    watch(selectedSurvey, () => {
      sortCodeBook();
    });
    return {
      projects,
      state,
      selectedProjectId,
      selectedSurvey,
      selectedSurveyList,
      selectedCaseList,
      codeBody,
      getProjects,
      getSurveyList,
      export2Excel,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-select {
  color: $primary;
}
.select-row {
  border-bottom: 2px solid $primary;
}
</style>
